<template>
  <v-dialog
    v-model="isOpen"
    width="400"
    :persistent="loading"
    scrollable
    content-class="rounded-lg"
  >
    <v-card>
      <v-card-title>Resetar senha</v-card-title>
      <v-card-text class="d-flex flex-column align-center gap-2">
        <v-avatar size="60" color="primary">
          <v-icon size="30" color="white">mdi-lock-reset</v-icon>
        </v-avatar>
        <h6>Redefinir senha do POS</h6>
        <v-card v-if="user" outlined class="d-flex gap-2 align-center gap-3 pa-3 rounded-lg w-full">
          <base-avatar :seed="user?.id" size="50" :src="user?.photo" />
          <div>
            <small class="d-block text-overline lh-1">Operador</small>
            <b class="d-block">{{ user.name }}</b>
            <small>{{ user.phone }}</small>
          </div>
        </v-card>
        <v-alert v-if="error" type="error" class="w-full">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="close">Cancelar</v-btn>
        <v-spacer />
        <v-btn color="primary" :loading="loading" @click="resetPassword">
          Resetar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";

export default {
  name: "PosResetPassword",
  data: () => ({
    isOpen: false,
    user: null,
    loading: false,
    error: null,
  }),
  methods: {
    open(user) {
      this.isOpen = true;
      this.user = user;
    },
    close() {
      this.isOpen = false;
      this.user = null;
    },
    async resetPassword() {
      try {
        this.loading = true;
        this.error = false;
        await POS.resetPassword(
          this.selectedOrganization.id,
          this.session.id,
          this.user.id
        );
        this.$emit("password-reset", this.user.id);
        this.close();
      } catch (error) {
        this.error = error.message || "Erro ao resetar senha";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  props: {
    session: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
