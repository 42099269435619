<template>
  <v-dialog v-model="dialog" max-width="500" persistent eager>
    <v-card :loading="loading" rounded="lg">
      <v-card-title>
        Adicionar registro {{ party ? "no Evento" : "na Organização" }}
      </v-card-title>
      <v-card-text>
        <p class="mb-0">
          Descreva o que foi feito, cole uma imagem ou captura de tela.
        </p>
        <div
          ref="meuEditor"
          contenteditable="true"
          @paste="onPaste"
          @input="onInput"
          style="
            border: 1px solid #ccc;
            min-height: 200px;
            padding: 10px;
            white-space: pre-wrap;
          "
        ></div>

        <v-card outlined class="pa-2 mt-4">
          <b>Anexos</b>
          <div class="d-flex flex-wrap gap-2">
            <p v-if="attachments.length === 0" class="mb-0">
              Nenhum anexo, cole uma imagem ou captura de tela.
            </p>
            <div
              v-for="(attachment, index) in attachments"
              :key="index"
              class="position-relative"
            >
              <v-img
                v-if="attachment.type === 'image'"
                :src="attachment.url"
                width="100"
                height="100"
                :aspect-ratio="1"
                rounded="xl"
              >
                <v-btn
                  icon
                  @click="removeAttachment(index)"
                  color="red"
                  small
                  :disabled="loading"
                >
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </v-img>
            </div>
          </div>
        </v-card>
        <v-alert v-if="error" type="error">{{ error }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="close" :disabled="loading"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="saveDaily"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import TECNICIAN from "@/services/admin/tecnician";

export default {
  props: {
    party: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      error: null,
      resolve: null,
      reject: null,
      valid: false,
      description: "",
      attachments: [],
    };
  },
  methods: {
    async open() {
      return new Promise((resolve, reject) => {
        this.dialog = true;
        this.resolve = resolve;
        this.reject = reject;

        this.description = "";
        this.attachments = [];
        this.valid = false;

        this.$nextTick(() => {
          if (this.$refs.meuEditor) {
            this.$refs.meuEditor.innerHTML = "";
            this.$refs.meuEditor.focus();
          }
        });
      });
    },
    onInput(e) {
      const editor = this.$refs.meuEditor;
      this.description = editor.innerText;
      this.valid = this.description.length > 5;
    },
    onPaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const items = clipboardData.items;
      let blob = null;
      // Verifica se algum item colado é uma imagem
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") === 0) {
          blob = items[i].getAsFile();
          break;
        }
      }

      if (blob) {
        // Impedir a imagem de aparecer diretamente no campo
        event.preventDefault();
        this.attachments.push({
          type: "image",
          file: blob,
          url: URL.createObjectURL(blob),
        });
      }
      // Caso contrário, se não é imagem, deixa o colar acontecer naturalmente
    },
    removeAttachment(index) {
      this.attachments.splice(index, 1);
    },
    close() {
      this.dialog = false;
      if (this.reject) this.reject();
    },
    async saveDaily() {
      try {
        this.loading = true;
        this.error = null;
        var { daily } = await TECNICIAN.daily.create(
          this.selectedOrganization.id,
          { body: this.description, partyId: this.party?.id || undefined }
        );

        const attachmentsResponse = await this.addAttachments(daily.id);
        daily.attachments = attachmentsResponse;

        if (this.resolve) this.resolve(daily);
        this.resolve = null;
        this.reject = null;
        this.close();
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    async addAttachments(dailyId) {
      var attachmentsResponse = [];
      for (const attachment of this.attachments) {
        const { url } = await TECNICIAN.daily.addAttachment(
          this.selectedOrganization.id,
          dailyId,
          attachment.file
        );
        attachmentsResponse.push(url);
      }
      return attachmentsResponse;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
};
</script>

<style scoped>
.position-absolute {
  position: absolute;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
</style>
