<template>
  <div>
    <v-dialog
      v-model="isOpen"
      width="700"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
      content-class="rounded-lg"
    >
      <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
        <v-card-text class="ma-0 pa-6">
          <v-card
            class="d-flex gap-4 align-center pa-2 mb-4"
            outlined
            rounded="lg"
          >
            <v-img
              v-if="device?.Model?.image"
              :src="
                (device?.acquirer &&
                  device?.Model?.imageVariants?.[device.acquirer]) ||
                device?.Model?.image
              "
              max-height="55"
              max-width="55"
            />
            <v-icon v-else color="primary" size="55">
              mdi-cash-register
            </v-icon>
            <div class="flex-grow-1">
              <h6 class="mb-0">{{ name || address?.name }}</h6>
              <span class="text-12 lh-1">SN: {{ device?.serialNumber }}</span>
            </div>
          </v-card>
          <v-text-field
            v-model="name"
            label="Nome"
            outlined
            dense
            class="mt-4"
            :rules="[
              (v) => !!v || 'Name é obrigatório',
              (v) =>
                (v && v.length <= 30) || 'Name deve ter menos de 30 caracteres',
            ]"
          />

          <h6 class="mb-0">Formas de pagamento</h6>
          <div class="d-flex flex-wrap gap-x-4 mb-4">
            <v-checkbox
              v-model="allowPaymentMethods"
              label="Dinheiro"
              value="money"
              class="d-flex"
              hide-details
            />
            <v-checkbox
              v-model="allowPaymentMethods"
              label="PIX"
              value="pix"
              class="d-flex"
              hide-details
            />
            <v-checkbox
              v-model="allowPaymentMethods"
              label="Cartão de Crédito"
              value="credit"
              class="d-flex"
              hide-details
            />
            <v-checkbox
              v-model="allowPaymentMethods"
              label="Cartão de Débito"
              value="debit"
              class="d-flex"
              hide-details
            />
          </div>

          <v-switch
            v-if="hasPermission(524288)"
            v-model="allowCourtesy"
            :disabled="loading"
            label="Permitir emitir cortesia"
            hide-details
            class="mt-4"
          />

          <v-switch
            v-model="requireClientRegistration"
            :disabled="loading"
            label="Requerer cadastro do cliente"
            hide-details
            class="mt-4"
          />

          <h6 class="mt-5">Operadores</h6>

          <p class="text-center text-secondary py-2" v-if="!users.length">
            Ao menos um operador é obrigatório
          </p>

          <div v-for="(user, i) in users" :key="i" class="d-flex gap-2 mb-4">
            <base-avatar :src="user?.photo" :seed="user?.id" size="45" />
            <div class="flex-grow-1">
              <b class="d-block"> {{ user.name }}</b>
              <small class="lh-1">{{ user.phone }}</small>
            </div>
            <v-tooltip top v-if="user.password" key="has-password">
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="resetPassword(user)"
                >
                  <v-icon>mdi-lock-reset</v-icon>
                </v-btn>
              </template>
              <span>Redefinir senha</span>
            </v-tooltip>
            <v-tooltip top v-else key="no-password">
              <template #activator="{ on, attrs }">
                <v-icon color="error" v-bind="attrs" v-on="on">
                  mdi-lock-off
                </v-icon>
              </template>
              <span>Senha não definida</span>
            </v-tooltip>
          </div>

          <v-alert v-if="error" type="error" class="mb-0 mt-4">
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-card-actions class="d-flex align-end gap-1 justify-space-between">
          <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
          <v-btn
            color="success"
            :disabled="!address || !users.length"
            :loading="loading"
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <pos-reset-password
      :session="session"
      ref="resetPasswordModal"
      @password-reset="passwordReseted"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import POS from "@/services/admin/pos";
import PhoneInput from "../../../global/PhoneInput.vue";
import PosResetPassword from "./PosResetPassword.vue";
export default {
  components: {
    PhoneInput,
    PosResetPassword,
  },
  data: () => ({
    isOpen: false,
    loading: false,
    device: null,
    session: null,
    users: [],
    error: false,
    name: null,
    address: null,
    allowCourtesy: false,
    requireClientRegistration: false,
    allowPaymentMethods: ["money", "pix", "credit", "debit"],
  }),

  methods: {
    open(session) {
      this.isOpen = true;
      this.loading = false;
      this.error = false;
      this.users = session.Users;
      this.allowPaymentMethods = session.allowPaymentMethods || [];
      this.requireClientRegistration =
        session.requireClientRegistration || false;

      this.session = Object.assign({}, session);
      this.address = Object.assign({}, session.Address);
      this.name = session.name || this.address.name;
      this.device = Object.assign({}, session.Device);
      if (session && session.allowPaymentMethods.includes("courtesy")) {
        this.allowCourtesy = true;
        this.allowPaymentMethods = this.allowPaymentMethods.filter(
          (m) => m !== "courtesy"
        );
      }
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.address = null;
      this.device = null;
      this.session = null;
      this.users = [];
      this.allowPaymentMethods = ["money", "pix", "credit", "debit"];
      this.allowCourtesy = false;
    },

    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;

        const allowPaymentMethods = this.allowPaymentMethods;
        if (this.allowCourtesy) allowPaymentMethods.push("courtesy");

        await POS.editPos(orgId, this.session.id, {
          name: this.name,
          address: this.address,
          allowPaymentMethods: allowPaymentMethods,
          requireClientRegistration: this.requireClientRegistration,
        });

        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
    resetPassword(user) {
      this.$refs.resetPasswordModal.open(user);
    },
    passwordReseted(userId) {
      this.users = this.users.map((u) =>
        u.id === userId ? { ...u, password: false } : u
      );
    },
  },
  filters: {},
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$parent.$on("edit-adopt-pos-modal", this.open);
  },
  created() {},
};
</script>

<style></style>
