<template>
  <div class="d-flex flex-column gap-3">
    <div class="d-flex justify-end">
      <v-btn-toggle v-model="displayType" mandatory color="primary">
        <v-btn value="payments" x-small>
          <v-icon
            left
            small
            :color="displayType === 'payments' ? 'primary' : ''"
          >
            mdi-cash-multiple
          </v-icon>
          Pagamentos
        </v-btn>
        <v-btn value="tickets" x-small>
          <v-icon
            left
            small
            :color="displayType === 'tickets' ? 'primary' : ''"
          >
            mdi-ticket
          </v-icon>
          Ingressos
        </v-btn>
      </v-btn-toggle>
    </div>
    <!-- {{ computedItems }} -->
    <v-data-table
      :headers="computedHeaders"
      :items="computedItems"
      :loading="loading"
      :items-per-page="5"
      sort-by="createdAt"
      sort-desc
    >
      <template v-slot:item.id="{ item }"> #{{ item?.id | shortId }} </template>
      <template v-slot:item.createdAt="{ item }">
        {{
          item?.createdAt || item?.Payment?.createdAt | date("DD/MM/YY HH:mm")
        }}
      </template>
      <template v-slot:item.amount="{ item }">
        <b>{{ item?.amount | currency(true) }}</b>
        <p class="mb-0 text-12" v-if="displayType === 'payments'">
          {{ item?.Ticket.length }} ingresso{{
            item?.Ticket.length > 1 ? "s" : ""
          }}
        </p>
      </template>
      <template v-slot:item.paymentType="{ item }">
        {{
          PAYMENT.paymentType[item?.paymentType || item?.Payment?.paymentType]
            ?.text
        }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          :color="
            PAYMENT.ticketStatus[item.status || item?.Payment?.status]?.color
          "
          text-color="white"
          small
          label
        >
          {{ PAYMENT.ticketStatus[item.status || item?.Payment?.status]?.text }}
          <small
            v-if="displayType === 'tickets' && item?.TicketEntry?.length"
            class="ml-2"
          >
            (Usado)
          </small>
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon small @click="openModal(item)">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <pos-payment-details />
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";
import PosPaymentDetails from "./PosPaymentDetails.vue";

export default {
  components: { PosPaymentDetails },
  props: {
    session: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    PAYMENT,
    displayType: "payments",
    headers: [
      {
        text: "#pagamento",
        value: "id",
        class: "px-2",
        cellClass: "px-2",
        types: ["payments"],
      },
      {
        text: "#ingresso",
        value: "id",
        class: "px-2",
        cellClass: "px-2",
        types: ["tickets"],
      },
      { text: "Data", value: "createdAt" },
      { text: "Valor", value: "amount" },
      { text: "Tipo", value: "paymentType" },
      { text: "Status", value: "status" },
      {
        text: "",
        value: "action",
        class: "px-0",
        cellClass: "px-0",
        sortable: false,
        types: ["payments"],
      },
    ],
  }),
  methods: {
    openModal(item) {
      this.$root.$emit("payment-pos-detail", item);
    },
  },
  computed: {
    computedHeaders() {
      return this.headers.filter((header) => {
        if (!header.types) return true;
        return header.types.includes(this.displayType);
      });
    },
    computedItems() {
      if (this.displayType === "payments") return this.data?.payments || [];
      return this.data.payments.reduce((acc, { Ticket, ...Payment }) => {
        Ticket.forEach((ticket) => acc.push({ ...ticket, Payment, createdAt: Payment.createdAt }));
        return acc;
      }, []);
    },
  },
};
</script>

<style></style>
