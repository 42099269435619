import http from "@/http-common";

const getDaily = async (orgId, partyId) => {
  return await http
    .get(`/admin/organization/${orgId}/daily`, { params: { partyId } })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const createDaily = async (orgId, body) => {
  return await http
    .post(`/admin/organization/${orgId}/daily`, body)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const addAttachment = async (orgId, dailyId, attachment) => {
  const formData = new FormData();
  formData.append("file", attachment);
  return await http
    .post(`/admin/organization/${orgId}/daily/${dailyId}/attachment`, formData)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  daily: {
    get: getDaily,
    create: createDaily,
    addAttachment,
  },
};
