<template>
  <v-dialog v-model="isOpen" max-width="600" scrollable>
    <v-card>
      <v-card-title class="text-16 pb-1 justify-space-between">
        <span class="d-flex align-center gap-1 lh-1">
          Compra
          <base-copier type="chip" :value="payment?.id || ''" hide-icon>
            <template v-slot:default="{ copy, icon }">
              <v-chip
                @click="copy"
                x-small
                label
                class="text-overline font-weight-medium"
              >
                #{{ payment?.id | shortId }}
                <v-icon right x-small>{{ icon }}</v-icon>
              </v-chip>
            </template>
          </base-copier>
        </span>
        <v-btn
          v-if="payment"
          x-small
          depressed
          rounded
          :loading="loading"
          @click="refreshTicketPayment"
          :color="PAYMENT.ticketStatus[payment?.status]?.color"
        >
          <v-icon left small>
            {{ PAYMENT.ticketStatus[payment?.status]?.icon }}
          </v-icon>
          {{ PAYMENT.ticketStatus[payment?.status]?.text }}
        </v-btn>
        <v-skeleton-loader
          v-else
          x-small
          width="100"
          height="22"
          type="card"
          class="rounded-xl"
        />
      </v-card-title>
      <v-card-text class="d-flex flex-column gap-3 pt-2">
        <v-card
          outlined
          class="d-flex justify-space-around flex-wrap gap-x-6 gap-y-3 pa-3"
        >
          <div class="text-center">
            <p class="mb-0 lh-1">Data da compra</p>
            <p class="mb-0 font-weight-bold">
              {{ payment.createdAt | date("DD/MM/YYYY HH:mm") }}
            </p>
          </div>
          <div
            class="text-center"
            v-if="payment.status === 'pending' && payment.validity"
          >
            <p class="mb-0 lh-1">Vencimento</p>
            <p class="mb-0 font-weight-bold">
              {{ payment.validity | fromNow }}
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Forma de pagamento</p>
            <p class="mb-0 font-weight-bold">
              {{ PAYMENT.paymentType[payment.paymentType]?.text }}
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Valor Pago</p>
            <p class="mb-0 font-weight-bold">
              {{ (payment.amount - payment.clientFee) | currency }}
              <span v-if="payment.clientFee > 0" class="text-caption">
                + {{ payment.clientFee | currency }}
              </span>
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Valor liquido</p>
            <p class="mb-0 font-weight-bold">
              {{ (payment.amount - payment.platformFee) | currency }}
            </p>
          </div>
        </v-card>
        <v-card outlined class="pa-3 py-2">
          <span class="text-overline lh-1">Evento</span>
          <h6 class="mb-0 font-weight-bold">
            {{ party.name }}
          </h6>
        </v-card>

        <v-card outlined class="pa-3">
          <h6 class="mb-0">Ingressos</h6>
          <v-data-table
            :headers="headers"
            :expanded.sync="expanded"
            :items="payment.Ticket"
            :items-per-page="payment.Ticket.length"
            :hide-default-footer="true"
            item-key="id"
            selectable-key="id"
            order-by="TicketBlock.TicketGroup.name"
          >
            <template #item.id="{ item }">
              <span class="text-overline lh-1">#{{ item.id | shortId }} </span>
            </template>
            <template #item.Owner.name="{ item }">
              <b v-if="!item.Owner">Não identificado</b>
              <template v-else>
                <b>{{ item.Owner?.name || "Conta não criada" }} </b>
                <p class="text-14 mb-0 lh-1">{{ item.Owner.phone }}</p>
              </template>
            </template>
            <template #item.TicketBlock.TicketGroup.name="{ item }">
              <b>
                {{ item?.TicketBlock?.TicketGroup?.name }}
              </b>
              <p class="text-14 mb-0 lh-1">
                {{ item.TicketBlock.name }}
              </p>
            </template>
            <template #item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="
                      hasPermission(128) &&
                      !PAYMENT.status_db_groups.canceled.includes(
                        payment.status.value
                      )
                    "
                    @click="transfer(item)"
                    icon
                    v-on="on"
                    :disabled="loading"
                  >
                    <v-icon> mdi-account-arrow-right </v-icon>
                  </v-btn>
                </template>
                <span>Transferir</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-btn block text @click="isOpen = false"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PAYMENT from "@/utils/payment";
import moment from "moment";

export default {
  data: () => ({
    PAYMENT,
    isOpen: false,
    payment: null,
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Dono", value: "Owner.name" },
      {
        text: "Ingresso",
        value: "TicketBlock.TicketGroup.name",
      },
    ],
  }),
  methods: {
    open(payment) {
      this.isOpen = true;
      this.payment = payment;
    },
    close() {
      this.isOpen = false;
      this.payment = null;
    },
  },
  computed: {
    party() {
      if (!this.payment) return null;
      return this.payment.Ticket[0]?.TicketBlock?.TicketGroup?.Party;
    },
  },
  filters: {
    fromNow(value) {
      return moment(value).fromNow();
    },
  },
  mounted() {
    this.$root.$on("payment-pos-detail", this.open);
  },
};
</script>

<style>
</style>