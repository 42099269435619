<template>
  <v-alert v-if="code" type="info" icon="mdi-lock" dense text>
    <div class="d-flex align-center justify-space-between flex-wrap gap-2">
      <span>Token para reimpressão e fechamento de caixa</span>
      <v-chip
        class="pl-2"
        :small="$vuetify.breakpoint.smAndDown"
        style="min-width: fit-content"
        @click="copyCode"
      >
        <v-slide-x-reverse-transition hide-on-leave>
          <v-progress-circular
            v-if="!copied"
            :size="$vuetify.breakpoint.smAndDown ? 16 : 20"
            :width="5"
            :rotate="0"
            :value="remaining"
            class="mr-2"
            :color="remaining > 17 ? 'info' : 'error'"
          />
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition hide-on-leave>
          <v-icon color="success" class="mr-1" v-if="copied">
            mdi-check
          </v-icon>
        </v-slide-x-reverse-transition>
        <span
          class="lh-1 font-weight-bold text-overline"
          :style="`font-size: ${
            $vuetify.breakpoint.smAndDown ? 12 : 14
          }px !important`"
        >
          {{ code | formatTOTP }}
        </span>
      </v-chip>
    </div>
  </v-alert>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PosTotpToken",
  data() {
    return {
      // TOTP
      totp: null,
      code: null,
      remaining: 0,
      interval: null,
      copied: false,
    };
  },
  methods: {
    init() {
      const { TOTP, Secret } = window.OTPAuth;
      this.totp = new TOTP({
        issuer: "TicketMe",
        label: this.selectedOrganization.name,
        algorithm: "SHA1",
        digits: 6,
        period: 60,
        secret: Secret.fromUTF8(`${this.selectedOrganization.id}`),
      });
      this.setInterval();
    },
    generateCode() {
      this.code = this.totp.generate();
      this.copied = false;
    },
    setInterval() {
      if (this.interval) clearInterval(this.interval);
      this.generateCode();
      const secondsToNextCode = 60 - (new Date().getSeconds() % 60);
      this.remaining = (secondsToNextCode / 60) * 100;
      this.interval = setInterval(() => {
        if (this.remaining > 1) this.remaining -= 100 / 120;
        else {
          this.remaining = 101;
          this.generateCode();
        }
      }, 500);
    },
    copyCode() {
      if (this.copied || !this.code) return;
      navigator.clipboard.writeText(this.code);
      this.copied = true;
      setTimeout(() => (this.copied = false), 1000);
    },
  },
  watch: {
    selectedOrganization() {
      this.init();
    },
  },
  filters: {
    formatTOTP(code) {
      return code ? code.match(/.{1,2}/g).join(" ") : "";
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.init();
  },
};
</script>
