<template>
  <div>
    <v-btn
      v-if="user.MasterPermission"
      fab
      dark
      style="position: fixed; z-index: 5"
      :style="positions[position]"
      color="grey lighten-3"
      @click="open"
    >
      <v-icon color="primary"> mdi-notebook </v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="700" scrollable>
      <v-card :loading="loading" rounded="lg">
        <v-card-title>
          <span>Diário {{ party ? "do Evento" : "da Organização" }}</span>
          <v-spacer></v-spacer>
          <v-btn
            small
            icon
            @click="getDailys"
            :disabled="loading"
            :loading="loading"
            class="mr-2"
          >
            <v-icon small> mdi-refresh </v-icon>
          </v-btn>
          <v-btn
            small
            depressed
            color="primary"
            @click="addDaily"
            :disabled="loading"
          >
            <v-icon> mdi-plus </v-icon>
            Registro
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- {{ dailys }} -->
          <div class="d-flex flex-column gap-2">
            <v-text-field
              v-if="dailys.length"
              v-model="search"
              label="Pesquisar"
              outlined
              prepend-inner-icon="mdi-magnify"
              hide-details
              dense
              class="mt-1"
            />
            <p
              v-if="!dailys.length && !loading"
              class="my-6 text-16 text-center"
            >
              Nenhum registro encontrado
            </p>
            <p
              v-else-if="!results.length && !loading"
              class="my-6 text-16 text-center"
            >
              Nenhum registro encontrado para "{{ search }}"
            </p>
            <v-card
              v-for="daily in results"
              :key="daily.id"
              class="pa-2"
              outlined
              rounded="lg"
            >
              <div class="d-flex align-center gap-2 mb-2">
                <base-avatar :seed="daily.userId" :src="daily.User?.photo" />
                <b>{{ daily.User?.name }}</b>
                <v-spacer></v-spacer>
                {{ daily.createdAt | date("DD/MM/yyyy HH:mm") }}
              </div>
              <p
                v-for="line in daily.body.split('\n')"
                class="mb-0"
                v-text="line"
              ></p>

              <div v-if="daily.attachments.length" class="mt-2">
                <b>Anexos</b>
                <div class="d-flex flex-wrap gap-2 px-1">
                  <div
                    v-for="(attachment, index) in daily.attachments"
                    :key="index"
                  >
                    <v-img
                      :src="attachment"
                      width="80"
                      class="rounded-lg"
                      height="80"
                      :aspect-ratio="1"
                      @click="viewImage(attachment)"
                    />
                  </div>
                </div>
              </div>
            </v-card>
          </div>
          <v-alert v-if="error" type="error">{{ error }}</v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
    <TecnicianDailyCreate ref="createDaily" :party="party" />

    <v-dialog
      v-model="imageDialog.open"
      max-width="900"
      :overlay-opacity="0.95"
      content-class="elevation-0"
    >
      <v-img :src="imageDialog.url" contain max-height="80vh">
        <div
          class="d-flex justify-center gap-2"
          style="position: absolute; bottom: 16px; width: 100%"
        >
          <v-card rounded="pill" elevation="0">
            <v-btn
              rounded
              small
              color="primary"
              @click="downloadImage"
              :loading="imageDialog.status === 'downloading'"
              :disabled="!!imageDialog.status"
            >
              <v-icon left> mdi-download </v-icon>
              Baixar
            </v-btn>
          </v-card>
          <v-card rounded="pill" elevation="0">
            <v-btn
              rounded
              color="primary"
              small
              @click="copyImage"
              :loading="imageDialog.status === 'copying'"
              :disabled="!!imageDialog.status"
            >
              <v-icon left>
                mdi-{{
                  imageDialog.status === "copied" ? "check" : "content-copy"
                }}
              </v-icon>
              {{ imageDialog.status === "copied" ? "Copiado" : "Copiar" }}
            </v-btn>
          </v-card>
          <v-card rounded="pill" elevation="0">
            <v-btn small icon @click="imageDialog.open = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card>
        </div>
      </v-img>
    </v-dialog>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import { mapGetters } from "vuex";
import TECNICIAN from "@/services/admin/tecnician";
import TecnicianDailyCreate from "./TecnicianDailyCreate.vue";
export default {
  components: {
    TecnicianDailyCreate,
  },
  props: {
    party: Object,
    position: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      fuse: null,
      search: "",
      dialog: false,
      dailys: [],
      loading: false,
      error: null,
      results: [],
      imageDialog: { open: false, url: null, status: null },
      positions: [
        {
          bottom: "146px",
          right: "16px",
        },
        {
          bottom: "76px",
          right: "16px",
        },
        {
          bottom: "16px",
          right: "16px",
        },
      ],
    };
  },
  methods: {
    async open() {
      this.dialog = true;
      if (!this.dailys.length) this.getDailys();
    },
    viewImage(url) {
      this.imageDialog.url = url;
      this.imageDialog.open = true;
    },
    async downloadImage() {
      try {
        this.imageDialog.status = "downloading";

        const response = await fetch(this.imageDialog.url);
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "attachment";
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.log(e);
      } finally {
        this.imageDialog.status = null;
      }
    },
    async copyImage() {
      try {
        this.imageDialog.status = "copying";
        const response = await fetch(this.imageDialog.url);
        const blob = await response.blob();
        navigator.clipboard.write([
          new ClipboardItem({
            "image/png": blob,
          }),
        ]);
        this.imageDialog.status = "copied";
        setTimeout(() => {
          this.imageDialog.status = null;
        }, 2000);
      } catch (e) {
        console.log(e);
      }
    },
    async getDailys() {
      try {
        this.loading = true;
        this.error = null;
        const { dailys } = await TECNICIAN.daily.get(
          this.selectedOrganization.id,
          this.party?.id
        );
        this.dailys = dailys;
        this.mountFuse();
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    async addDaily() {
      const daily = await this.$refs.createDaily.open();
      this.dailys.unshift(daily);
      this.mountFuse();
    },
    mountFuse() {
      this.fuse = new Fuse(this.dailys, {
        keys: ["body"],
        threshold: 0.6,
      });
      this.performSearch();
    },
    performSearch() {
      if (!this.fuse) return;
      if (!this.search) return (this.results = this.dailys);
      this.results = this.fuse.search(this.search).map((r) => r.item);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    search() {
      this.performSearch();
    },
    party: {
      deep: true,
      handler() {
        this.dailys = [];
      },
    },
    selectedOrganization: {
      deep: true,
      handler() {
        this.dailys = [];
      },
    },
  },
};
</script>
